import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { PrismicRichText, SliceZone } from '@prismicio/react';
import { FaTwitter, FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import { HiLink } from 'react-icons/hi';
import { v4 as uuidv4 } from 'uuid';

import Layout from '@/components/Layout';
import Typography from '@/components/Typography';
import { Box } from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from 'react-share';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import { format, parseISO } from 'date-fns';
import { calculateReadTime } from '@/utils/calculateReadTime';
import components from '../slices';

const PressPostTemplate = ({ data, path, pageContext }) => {
  const {
    body: slices,
    social_body: socialBody,
    press_post_title: pressPostTitle,
    press_post_description: pressPostDescription,
    press_post_image: pressPostImage,
    press_post_tag: pressPostTag,
    press_post_image_download_link: pressPostImageDownloadLink,
    press_post_image_download_label: pressPostImageDownloadLabel,
  } = data.prismicPressPost.data;
  const publishDate = data.prismicPressPost.first_publication_date;
  const formattedDate = format(parseISO(publishDate), 'MMMM do yyyy');
  const isAuthor = data.prismicPressPost.data.press_post_author.document;

  const { pressPostAuthorName, pressPostAuthorImage } = useMemo(() => {
    if (isAuthor) {
      return {
        pressPostAuthorName:
          data.prismicPressPost.data.press_post_author.document.data
            .author_name,
        pressPostAuthorImage:
          data.prismicPressPost.data.press_post_author.document.data
            .author_image,
      };
    }
    return {};
  }, [isAuthor]);

  /// get raw text of post and calculate a read time
  const readTime = useMemo(() => {
    const raw = [];
    const post = slices.filter(
      (slice) => slice.slice_type === 'press_rich_text',
    );

    if (post.length > 0 && post[0].items) {
      for (let i = 0; i < post.length; i += 1) {
        for (let z = 0; z < post[i].items.length; z += 1) {
          raw.push(post[i].items[z].text_body.text);
        }
      }
    }

    return calculateReadTime(raw.join(''));
  });

  const postUrl = `${process.env.GATSBY_BASE_URL}${path}`;

  // html head data
  const headData = useMemo(
    () =>
      socialBody.map(({ primary: { description, image, title } }) => ({
        title: title.text,
        description: description.text,
        ogTitle: title.text,
        ogDescription: description.text,
        ogImageUrl: image.url,
      }))[0],
    [socialBody],
  );

  return (
    <Layout
      {...headData}
      lang={pageContext.lang}
      altLangs={pageContext.alternate_languages}
    >
      <article>
        <Box
          sx={{
            px: { xs: 3, sm: 4 },
            pb: { xs: 4, sm: 6, lg: 3 },
            pt: { xs: 4, sm: 6 },
          }}
        >
          <Box
            sx={{
              maxWidth: 800,
              width: 1,
              mx: 'auto',
              display: `flex`,
              alignItems: { xs: `center`, lg: `end` },
              justifyContent: `space-between`,
              flexDirection: `column`,
              gap: 3,
            }}
          >
            <Box
              sx={{
                height: 1,
                display: `flex`,
                flexDirection: `column`,
                gap: 2,
              }}
            >
              {pressPostTag.richText.map(({ text, type }) => (
                <CustomTypography
                  key={uuidv4()}
                  customVariant="heading6"
                  variant={type}
                  sx={{ color: '#544D4D' }}
                >
                  {text}
                </CustomTypography>
              ))}
              {pressPostTitle.richText.map(({ text, type }) => (
                <CustomTypography
                  key={uuidv4()}
                  variant={type}
                  customVariant="heading2"
                >
                  {text}
                </CustomTypography>
              ))}
              {pressPostDescription.richText.map(({ text, type }) => (
                <CustomTypography
                  key={uuidv4()}
                  variant={type}
                  customVariant="paragraphLarge"
                >
                  {text}
                </CustomTypography>
              ))}
              <Box
                sx={{
                  display: `flex`,
                  justifyContent: `space-between`,
                  alignItems: `end`,
                }}
              >
                <Box
                  sx={{ display: 'flex', flexDirection: `row`, gap: 1, mt: 1 }}
                >
                  <Box
                    sx={{
                      width: 48,
                      height: 48,
                      img: { borderRadius: '1000px', width: 1, height: 1 },
                    }}
                  >
                    {pressPostAuthorImage &&
                      pressPostAuthorImage.gatsbyImageData && (
                        <GatsbyImage
                          alt={pressPostAuthorImage.alt}
                          image={pressPostAuthorImage.gatsbyImageData}
                        />
                      )}
                  </Box>
                  <Box
                    sx={{ display: 'flex', flexDirection: `column`, gap: 1 }}
                  >
                    <Typography variant="paragraphBold">
                      {pressPostAuthorName}
                    </Typography>
                    <Typography variant="smallMuted">
                      {formattedDate} • {readTime.toString()} min read
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    mb: 0.5,
                    svg: {
                      fill: 'white',
                      width: 1,
                      height: 1,
                    },
                    button: {
                      backgroundColor: '#B6B3B3 !important',
                      width: 24,
                      height: 24,
                      borderRadius: '1000px',
                      display: `flex`,
                      alignItems: `center`,
                      justifyContent: `center`,
                      border: `none`,
                      cursor: `pointer`,
                      padding: `6px !important`,
                    },
                    display: `flex`,
                    gap: 1,
                  }}
                >
                  <TwitterShareButton
                    url={postUrl}
                    title={<PrismicRichText text={pressPostTitle} />}
                  >
                    <FaTwitter />
                  </TwitterShareButton>
                  <FacebookShareButton url={postUrl}>
                    <FaFacebookF />
                  </FacebookShareButton>
                  <LinkedinShareButton
                    url={postUrl}
                    title={<PrismicRichText text={pressPostTitle} />}
                    summary={<PrismicRichText text={pressPostDescription} />}
                  >
                    <FaLinkedinIn />
                  </LinkedinShareButton>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(postUrl);
                    }}
                    type="button"
                  >
                    <HiLink />
                  </button>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                position: `relative`,
                pb: `56.25%`,
                height: 0,
                overflow: `hidden`,
                width: 1,
                '.gatsby-image-wrapper': {
                  position: `absolute`,
                  top: 0,
                  left: 0,
                  width: 1,
                  height: 1,
                },
              }}
            >
              {pressPostImage.gatsbyImageData && (
                <GatsbyImage
                  alt={pressPostImage.alt}
                  image={pressPostImage.gatsbyImageData}
                />
              )}
              <Box
                component="a"
                href={pressPostImageDownloadLink.url}
                sx={{
                  position: `absolute`,
                  right: 16,
                  bottom: 16,
                  border: `none`,
                  backgroundColor: `inherit`,
                  cursor: `pointer`,
                }}
              >
                <img
                  src="/download-button.svg"
                  alt={pressPostImageDownloadLabel}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <SliceZone slices={slices} components={components} />
      </article>
    </Layout>
  );
};

export const query = graphql`
  query PressPostQuery($lang: String, $uid: String) {
    prismicPressPost(lang: { eq: $lang }, uid: { eq: $uid }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      first_publication_date
      data {
        social_body {
          ... on PrismicPressPostDataSocialBodyGeneralCard {
            primary {
              description {
                text
              }
              title {
                text
              }
              image {
                url
              }
            }
          }
          ... on PrismicPressPostDataSocialBodyTwitterCard {
            primary {
              description {
                text
              }
              title {
                text
              }
              image {
                url
              }
            }
          }
        }
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...PressPostRichText
          ...PressImgaeWithCaption
          ...PressContacts
          ...PressDownloadAllImages
          ...PressPostPageBanner
        }
        press_post_title {
          richText
        }
        press_post_description {
          richText
        }
        press_post_tag {
          richText
        }
        press_post_image {
          gatsbyImageData(width: 600, height: 600)
          alt
        }
        press_post_image_download_link {
          url
        }
        press_post_image_download_label
        press_post_author {
          document {
            ... on PrismicAuthor {
              data {
                author_name
                author_image {
                  gatsbyImageData
                  alt
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(PressPostTemplate);
